const forms = document.querySelectorAll('form');

// deaktiviert alle Submit-Buttons beim Abschicken eines Formulars
for (const form of forms) {
  const submitButtons = form.querySelectorAll('[type="submit"]:not(.multiple-submit)');

  if (form.classList.contains('confirm-submit')) {
    continue;
  }

  form.addEventListener('submit', () => {
    for (const submitButton of submitButtons) {
      submitButton.disabled = true;
    }
  });
}

// Sorgt dafür, dass JS auch nach Nutzen des Zurück-Buttons ausgeführt wird,
// dadurch werden deaktivierte Buttons wieder nutzbar
window.onunload = () => {};
